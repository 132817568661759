const imageUrls: string[] = [
    '../assets/Global-Projects-Map-Spinner/spinner-concept0001.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0002.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0003.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0004.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0005.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0006.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0007.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0008.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0009.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0010.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0011.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0012.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0013.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0014.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0015.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0016.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0017.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0018.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0019.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0020.svg',
    '../assets/Global-Projects-Map-Spinner/spinner-concept0021.svg'
  ];
  
  imageUrls.forEach((url: string) => {
    const img = new Image();
    img.src = url; 
  });
  